exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-orders-order-id-index-tsx": () => import("./../../../src/pages/orders/[orderId]/index.tsx" /* webpackChunkName: "component---src-pages-orders-order-id-index-tsx" */),
  "component---src-pages-products-index-tsx": () => import("./../../../src/pages/products/index.tsx" /* webpackChunkName: "component---src-pages-products-index-tsx" */),
  "component---src-pages-settlements-components-aggregated-bill-details-tsx": () => import("./../../../src/pages/settlements/components/AggregatedBillDetails.tsx" /* webpackChunkName: "component---src-pages-settlements-components-aggregated-bill-details-tsx" */),
  "component---src-pages-settlements-components-payment-charge-breakup-tsx": () => import("./../../../src/pages/settlements/components/PaymentChargeBreakup.tsx" /* webpackChunkName: "component---src-pages-settlements-components-payment-charge-breakup-tsx" */),
  "component---src-pages-settlements-components-pending-payment-info-tsx": () => import("./../../../src/pages/settlements/components/PendingPaymentInfo.tsx" /* webpackChunkName: "component---src-pages-settlements-components-pending-payment-info-tsx" */),
  "component---src-pages-settlements-components-platform-charges-tsx": () => import("./../../../src/pages/settlements/components/PlatformCharges.tsx" /* webpackChunkName: "component---src-pages-settlements-components-platform-charges-tsx" */),
  "component---src-pages-settlements-components-platform-fee-details-tsx": () => import("./../../../src/pages/settlements/components/PlatformFeeDetails.tsx" /* webpackChunkName: "component---src-pages-settlements-components-platform-fee-details-tsx" */),
  "component---src-pages-settlements-components-settlement-card-tsx": () => import("./../../../src/pages/settlements/components/SettlementCard.tsx" /* webpackChunkName: "component---src-pages-settlements-components-settlement-card-tsx" */),
  "component---src-pages-settlements-components-settlement-nav-tsx": () => import("./../../../src/pages/settlements/components/settlement-nav.tsx" /* webpackChunkName: "component---src-pages-settlements-components-settlement-nav-tsx" */),
  "component---src-pages-settlements-components-tabs-pending-tsx": () => import("./../../../src/pages/settlements/components/tabs/pending.tsx" /* webpackChunkName: "component---src-pages-settlements-components-tabs-pending-tsx" */),
  "component---src-pages-settlements-components-tabs-settled-tsx": () => import("./../../../src/pages/settlements/components/tabs/settled.tsx" /* webpackChunkName: "component---src-pages-settlements-components-tabs-settled-tsx" */),
  "component---src-pages-settlements-index-tsx": () => import("./../../../src/pages/settlements/index.tsx" /* webpackChunkName: "component---src-pages-settlements-index-tsx" */),
  "component---src-pages-settlements-settlement-id-index-tsx": () => import("./../../../src/pages/settlements/[settlementId]/index.tsx" /* webpackChunkName: "component---src-pages-settlements-settlement-id-index-tsx" */),
  "component---src-pages-stores-components-store-selection-state-tsx": () => import("./../../../src/pages/stores/components/StoreSelectionState.tsx" /* webpackChunkName: "component---src-pages-stores-components-store-selection-state-tsx" */),
  "component---src-pages-stores-index-tsx": () => import("./../../../src/pages/stores/index.tsx" /* webpackChunkName: "component---src-pages-stores-index-tsx" */)
}

