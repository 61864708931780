/* eslint-disable no-useless-escape */

interface RegexPatterns {
    number: string;
    phoneNumber: string;
    otp: string;
    pinCode: string;
    email: RegExp;
  }
  
  const Regex: RegexPatterns = {
    number: '[0-9]',
    phoneNumber: '[0-9]',
    otp: '[0-9]',
    pinCode: '[0-9]',
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  } as const;
  
  export default Regex;
  