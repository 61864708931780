import React from "react";
import "./src/styles/global.css"
import Wrapper from "./src/wrapper";
import { store } from './src/store';
import { Provider } from "react-redux";
import { isFullyAuthenticated } from "./src/utils/authentication";
import { isMobileUser } from './src/utils';

export const wrapRootElement = ({ element }) => {
  if (isMobileUser()) {
    window.location.href = "https://play.google.com/store/apps/details?id=mpaani.com.android.yodaapp"
  }

  return (
    <Provider store={store}>
     {element}
    </Provider>
  );
};

export const wrapPageElement = ({ element }) => {
  return isFullyAuthenticated() ? (
    <Wrapper>
      {element}
    </Wrapper>
  ) : (
  <>
    {element}
  </>
  );
};
