import Regex from './Regex';

// Helper function to join text with a delimiter
const joinText = (textElement: string | null | undefined, withDelimiter: boolean = true): string => {
  const text = textElement ? textElement.trim() : '';
  if (text === '') {
    return '';
  }
  return `${text}${withDelimiter ? ', ' : ''}`;
};
export const openUrl = (gmapUrl: string): void => {
  window.open(gmapUrl, '_blank');
};
// Function to get initials from a text string
export const getInitials = (text: string): string => {
  const initials = text.match(/\b\w/g) || [];
  return ((initials.shift() ?? '') + (initials.pop() ?? '')).toUpperCase();
};

// Interface for the address object
interface Address {
  flatNumber?: string;
  buildingName?: string;
  streetName?: string;
  localityName?: string;
  landmark?: string;
  addressText?: string;
  googleAddress?: string;
}

// Function to format an address string
export const getAddressString = (address: Address = {}): string => {
  if (address) {
    const {
      flatNumber,
      buildingName,
      streetName,
      localityName,
      landmark,
      addressText,
      googleAddress,
    } = address;
    return `${joinText(flatNumber)}${joinText(buildingName)}${joinText(streetName)}${joinText(
      localityName,
    )}${joinText(landmark)}${
      addressText ? joinText(addressText, false) : joinText(googleAddress, false)
    }`;
  }
  return address?.addressText || '';
};

// Function to validate fields using regex
export const validateRegex = (field: keyof typeof Regex, value: string | number): boolean => {
  switch (field) {
    case 'phoneNumber':
    case 'number': {
      const regex = new RegExp(`^${Regex[field]}{0,10}$`);
      return regex.test(value.toString());
    }
    case 'otp': {
      const regex = new RegExp(`^${Regex[field]}{0,4}$`);
      return regex.test(value.toString());
    }
    case 'pinCode': {
      const regex = new RegExp(`^${Regex[field]}{0,6}$`);
      return regex.test(value.toString());
    }
    default:
      return true;
  }
};

// Function to format a date as "Today", "Tomorrow", or a formatted date string
export const formatDateToTodayTomorrow = (date: string | Date): string => {
  const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    ...timeOptions,
  };

  // Get the current date and tomorrow's date
  const currentDate = new Date();
  const tomorrowDate = new Date();
  const yesterdayDate = new Date();
  tomorrowDate.setDate(currentDate.getDate() + 1);
  yesterdayDate.setDate(currentDate.getDate() - 1);

  // Convert all dates to UTC to ignore time zone differences
  currentDate.setUTCHours(0, 0, 0, 0);
  tomorrowDate.setUTCHours(0, 0, 0, 0);
  yesterdayDate.setUTCHours(0, 0, 0, 0);

  // Convert the input date to UTC
  const inputDate = new Date(date);
  inputDate.setUTCHours(0, 0, 0, 0);

  // Compare the input date with current and tomorrow's date
  if (inputDate.getTime() === currentDate.getTime()) {
    return `Today, ${new Date(date).toLocaleTimeString('en-IN', timeOptions)}`;
  }
  if (inputDate.getTime() === tomorrowDate.getTime()) {
    return `Tomorrow, ${new Date(date).toLocaleTimeString('en-IN', timeOptions)}`;
  }
  if (inputDate.getTime() === yesterdayDate.getTime()) {
    return `Yesterday, ${new Date(date).toLocaleTimeString('en-IN', timeOptions)}`;
  }

  // For any other date, return the original date in a formatted string
  return new Date(date).toLocaleDateString('en-IN', dateOptions);
};

/**
 * Extracts the error message from an SOA error object.
 *
 * @param {any} error - The error object potentially containing the SOA error message.
 * @returns {string} - The extracted error message or an empty string if not found.
 */
export const getSOAErrorText = (error: any): string => {
  const errorMsg = error?.response?.data?.errors?.[0]?.message ?? '';
  return errorMsg;
};

export const formatDate = (timestamp: Date) => {
  const date = timestamp.getDate();
  const month = timestamp.getMonth() + 1;
  const year = timestamp.getFullYear().toString();
  return `${
    date < 10 ? '0' : ''
  }${date}/${
    month < 10 ? '0' : ''
  }${month}/${year.slice(2)}`;
};


export const convertTime12HrsFormat = (time: any, lowercase = false) => {
  let hours = new Date(time).getHours();
  const minutes = new Date(time).getMinutes();
  const AMPM = hours >= 12 ? 'pm' : 'am';
  hours = hours > 12 ? hours - 12 : hours;
  const convertedTime = `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes} ${lowercase ? AMPM.toLowerCase() : AMPM}`;
  return convertedTime;
};


export const convertDateToDaysAgo = (dateString: string | number, isDateStringFormat = false) => {
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);
  const date = new Date(dateString);
  if (date.toDateString() === today.toDateString()) {
    return `Today, ${convertTime12HrsFormat(date)}`;
  }
  if (date.toDateString() === yesterday.toDateString()) {
    return 'Yesterday';
  }
  if (isDateStringFormat) {
    const parts = date.toDateString().split(' ');
    const isCurrentYear = (
      today.getFullYear() === Number(parts[3])
    );
    return `${parts[1]} ${parts[2]} ${isCurrentYear ? '' : parts[3]}`;
  }
  return formatDate(date);
};

export const isMobileUser = () => {
  const userAgent = navigator?.userAgent
  if (/android/i.test(userAgent)) {
    return typeof window !== "undefined" && /iPhone|iPad|iPod|Android|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
  } else {
    return false
  }
}

export const monthsMap = {
  long: {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  },
  short: {
    0: 'Jan',
    1: 'Feb',
    2: 'Mar',
    3: 'Apr',
    4: 'May',
    5: 'Jun',
    6: 'Jul',
    7: 'Aug',
    8: 'Sep',
    9: 'Oct',
    10: 'Nov',
    11: 'Dec',
  },
};

export const getMonthDateYearFormat = (epoch: number | string) => {
  const date = new Date(epoch);
  const currentDate = new Date();
  const currFullYear = currentDate.getFullYear();
  const dateFullYear = date.getFullYear();
  const currMonth = currentDate.getMonth();
  const dateMonth = date.getMonth();
  if (
    currFullYear === dateFullYear
    && currMonth === dateMonth
  ) {
    switch (currentDate.getDate() - date.getDate()) {
      case 0:
        return 'Today';
      case 1:
        return 'Yesterday';
      default:
        break;
    }
  }
  const dateStr = date.getDate() > 10 ? date.getDate() : `0${date.getDate()}`;
  return `${monthsMap.short[dateMonth as keyof typeof monthsMap.short]} ${dateStr},  ${dateFullYear}`;
};

export const lastday = (y, m) => {
  const month = new Date().getMonth();
  const date = new Date().getDate();
  if (month === m) {
    return new Date(y, m, date, 23, 59, 59, 999);
  }
  return new Date(y, m + 1, 0, 23, 59, 59, 999);
};

export const firstday = (y, m) => (
  new Date(y, m, 1, 0, 0, 0, 0)
);
